import React, { useState } from "react"

const ThemeContext = React.createContext()

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("")
  const handleSetTheme = () => {
    setTheme(theme === "" ? "dark" : "")
  }
  return (
    <ThemeContext.Provider
      value={{ theme: theme, setTheme: () => handleSetTheme }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export { ThemeProvider, ThemeContext }
